<template>
  <div v-if="showCodPaid" class="text-states-green-dark paragraph-heavy px-16">
    {{ t('codPaid') }}
  </div>
  <div v-else class="d-flex justify-content-between">
    <molecule-modal>
      <template #activator>
        <div class="d-flex gap-8 cursor-pointer align-items-center px-16">
          <div class="text-base-red paragraph-heavy">
            {{ t('cod') }} {{ codAmount }} {{ currency }}
          </div>
          <info-icon class="text-grey-400-inactive" />
        </div>
      </template>

      <template #content="{ close }">
        <modal-content
          :title="t('cod')"
          without-mobile-illustration
          :illustration="canBePaid ? IllustrationPackageCod : IllustrationSandClock"
        >
          <template #description>
            <i18n-t :keypath="canBePaid ? 'payCodModalDesc' : 'codWaitingModalDesc'">
              <template #strong>
                <span
                  :class="{
                    'paragraph-large-heavy': viewport.isGreaterOrEquals('md'),
                    'paragraph-heavy': viewport.isLessThan('md'),
                  }"
                >
                  {{ t('codWaitingModalDescWait') }}
                </span>
              </template>
              <template #link>
                <a
                  :href="getLink(barcode, locale)"
                  :target="$device.isMobileOrTablet ? undefined : '_blank'"
                  class="link"
                >
                  {{ t('payCodModalDescLink') }}
                </a>
              </template>
              <template #close>
                <span class="link cursor-pointer" @click="close()">{{
                  t('payCodModalDescClose')
                }}</span>
              </template>
            </i18n-t>
          </template>
        </modal-content>
      </template>
    </molecule-modal>

    <atom-button
      v-if="canBePaid && viewport.isGreaterOrEquals('md')"
      :disabled="paying"
      class="pay-btn paragraph-small-heavy"
      :class="{ 'ps-24': paying }"
      @click="handlePayCod"
    >
      <atom-load-spinner v-if="paying" inner class="me-8" />
      {{ t('payCod') }}
    </atom-button>

    <teleport to="#teleports">
      <div
        v-if="canBePaid && viewport.isLessThan('md')"
        class="pay-mobile-wrapper px-16 pt-16 bg-base-white"
      >
        <div class="d-flex flex-column gap-8">
          <atom-button
            :disabled="paying"
            class="pay-btn paragraph-heavy w-100 justify-content-center"
            @click="handlePayCod"
          >
            <atom-load-spinner v-if="paying" inner class="me-8" />
            {{ t('payCod') }}
          </atom-button>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import InfoIcon from '@web/packeta-ui-styleguide/icons/status/icon-info.svg'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import IllustrationPackageCod from '~assets/illustrations/illustration-package-cod.svg'
import IllustrationSandClock from '~assets/illustrations/illustration-sand-clock.svg'
import { GetPacketByIdResponseDataItemPacketStatusId, Locales } from '~common/enums'
import type { CodProps } from '~common/types'
import AtomButton from '~components/atoms/AtomButton.vue'
import AtomLoadSpinner from '~components/atoms/AtomLoadSpinner.vue'
import MoleculeModal from '~components/molecules/MoleculeModal.vue'
import ModalContent from '~components/shared/ModalContent.vue'

const { t, locale } = useI18n()
const viewport = useViewport()
const { isMobileOrTablet } = useDevice()
const config = useRuntimeConfig()

const deeplink = 'https://mobile.api.packeta.com/mapi/navigate/packages/active'
const zasilkovnaUrl = 'https://www.zasilkovna.cz/mobilni-aplikace'
const packetaSkUrl = 'https://www.packeta.sk/mobilna-aplikacia'
const packetaHuUrl = 'https://www.packeta.hu/mobil-app'
const packetaRoUrl = 'https://www.packeta.ro/aplicatia-mobila'
const packetaComUrl = 'https://www.packeta.com/mobile-app'

const getLink = (shipmentNumber: string, locale: string) => {
  if (isMobileOrTablet) {
    return `${deeplink}/${shipmentNumber}`
  }

  switch (locale) {
    case Locales.CS:
      return zasilkovnaUrl
    case Locales.SK:
      return packetaSkUrl
    case Locales.HU:
      return packetaHuUrl
    case Locales.RO:
      return packetaRoUrl
    default:
      return packetaComUrl
  }
}

const props = defineProps<CodProps>()

const canBePaid = computed(() => {
  return [
    GetPacketByIdResponseDataItemPacketStatusId.READY_FOR_PICKUP,
    GetPacketByIdResponseDataItemPacketStatusId.READY_LAST_DAY,
  ].includes(props.packetStatusId)
})

const codAmount = computed(() => {
  const codNumber = parseFloat(props.cod)

  if (codNumber % 1 === 0) {
    return codNumber.toFixed(0)
  }

  return props.cod
})

const paying = ref(false)
const handlePayCod = () => {
  const { paymentUrl } = config.public
  paying.value = true

  window.location.href = `${paymentUrl}/${props.barcode}`
}
</script>

<style lang="scss" scoped>
@media (min-width: $media-md) {
  .pay-btn {
    margin-top: -20px;
    margin-bottom: 5px;
  }
}

.link {
  color: $base-red;
  text-decoration: underline;
}

.link:hover {
  color: $red-300;
  text-decoration: none;
}

.pay-mobile-wrapper {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  padding-bottom: 20px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
}

@media (max-width: $media-md) {
  .pay-btn {
    height: 50px;
  }
}
</style>
