// Helper to detect if the environment is server-side or client-side
const isServer = typeof window === 'undefined'

const secretKey = 'J8P9mV6LFfDmlz8iVxL3D5G2Fq9ts+LTGtTCvVIRcds='

/**
 * Derives a 256-bit AES key from the provided secret key.
 * Uses SHA-256 to hash the key into the correct length.
 * @returns {Promise<ArrayBuffer>} - The derived key
 */
async function deriveKey(): Promise<ArrayBuffer> {
  const enc = new TextEncoder()
  if (isServer) {
    // Server-side key derivation using Node.js crypto
    const crypto = await import('crypto')
    return crypto.createHash('sha256').update(secretKey).digest()
  } else {
    // Client-side key derivation using Web Crypto API
    return await window.crypto.subtle.digest('SHA-256', enc.encode(secretKey))
  }
}

/**
 * Encrypts a given object into a string
 * @param {Object} data - The data to encrypt
 * @returns {Promise<string>} - Encrypted string
 */
export async function encryptObject(data: Record<string, any>): Promise<string> {
  const jsonString = JSON.stringify(data)

  if (isServer) {
    // Server-side encryption using Node.js crypto module
    const crypto = await import('crypto')
    const key = await deriveKey() // 256-bit key from SHA-256 hash
    const iv = crypto.randomBytes(16) // Generate random IV (16 bytes)
    const cipher = crypto.createCipheriv('aes-256-cbc', key, iv)
    let encrypted = cipher.update(jsonString, 'utf8', 'hex')
    encrypted += cipher.final('hex')
    return `${iv.toString('hex')}:${encrypted}`
  } else {
    // Client-side encryption using Web Crypto API
    const keyMaterial = await deriveKey() // 256-bit key from SHA-256 hash
    const key = await window.crypto.subtle.importKey(
      'raw',
      keyMaterial,
      { name: 'AES-CBC' },
      false,
      ['encrypt'],
    )

    const iv = window.crypto.getRandomValues(new Uint8Array(16)) // Generate random IV
    const enc = new TextEncoder()
    const encryptedData = await window.crypto.subtle.encrypt(
      { name: 'AES-CBC', iv },
      key,
      enc.encode(jsonString),
    )

    // Convert encrypted data and IV to hex for storage/transmission
    const encryptedArray = new Uint8Array(encryptedData)
    const ivHex = Array.from(iv)
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('')
    const encryptedHex = Array.from(encryptedArray)
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('')

    return `${ivHex}:${encryptedHex}`
  }
}

/**
 * Decrypts an encrypted string back into an object
 * @param {string} encryptedData - The encrypted string
 * @returns {Promise<Object>} - Decrypted object
 */
export async function decryptString(encryptedData: string): Promise<Record<string, any>> {
  const [ivHex, encryptedHex] = encryptedData.split(':')

  if (isServer) {
    // Server-side decryption using Node.js crypto module
    const crypto = await import('crypto')
    const key = await deriveKey(secretKey) // 256-bit key from SHA-256 hash
    const iv = Buffer.from(ivHex, 'hex') // IV as Buffer
    const encryptedBuffer = Buffer.from(encryptedHex, 'hex') // Encrypted data as Buffer
    const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv)
    let decrypted = decipher.update(encryptedBuffer, 'hex', 'utf8')
    decrypted += decipher.final('utf8')
    return JSON.parse(decrypted)
  } else {
    // Client-side decryption using Web Crypto API
    const keyMaterial = await deriveKey(secretKey) // 256-bit key from SHA-256 hash
    const key = await window.crypto.subtle.importKey(
      'raw',
      keyMaterial,
      { name: 'AES-CBC' },
      false,
      ['decrypt'],
    )

    const iv = new Uint8Array(ivHex.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)))
    const encryptedArray = new Uint8Array(
      encryptedHex.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)),
    )

    const decryptedData = await window.crypto.subtle.decrypt(
      { name: 'AES-CBC', iv },
      key,
      encryptedArray,
    )

    const dec = new TextDecoder()
    const decryptedText = dec.decode(decryptedData)
    return JSON.parse(decryptedText)
  }
}
