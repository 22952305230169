<template>
  <div
    v-if="data"
    class="id-page-container container-fluid"
    :data-id="viewport.isGreaterOrEquals('md') ? DataIdPage.ID_PAGE : DataIdPage.ID_PAGE_MOBILE"
  >
    <div class="id-container container-fluid container-lg">
      <div class="id-page-row row m-0">
        <div
          class="col-12 col-lg-6 bg-grey-20-background d-flex flex-column align-self-baseline align-self-lg-auto align-items-lg-center px-24 px-sm-48 py-24 p-lg-0"
        >
          <left-side :packet-data="data" :info-items="infoItems" />
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column align-items-lg-center p-0 position-relative">
          <right-side :packet-data="data" :info-items="infoItems" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { DataIdPage } from '~common/enums/tests.ts'
import { getInfoItems } from '~common/helpers/infoItems.ts'
import type { IdPageProps } from '~common/types/pages.ts'

const LeftSide = defineAsyncComponent(() => import('~components/pages/id/left-side/index.vue'))
const RightSide = defineAsyncComponent(() => import('~components/pages/id/right-side/index.vue'))

const { t } = useI18n()
const viewport = useViewport()

const props = defineProps<IdPageProps>()

const infoItems = computed(() => {
  return getInfoItems(props.data.item, t)
})
</script>

<style lang="scss" scoped>
.id-page-container {
  padding: 0;
}

@media (min-width: $media-lg) {
  .id-page-container {
    padding-left: calc(100vw - 100%);
  }

  .id-page-row {
    min-height: calc(100dvh - 71px);
  }
}

@media (min-width: $media-lg) {
  .id-page-container {
    background: -moz-linear-gradient(90deg, $grey-20-background 50%, $base-white 50%);
    background: -webkit-linear-gradient(90deg, $grey-20-background 50%, $base-white 50%);
    background: linear-gradient(90deg, $grey-20-background 50%, $base-white 50%);
  }
}
</style>
