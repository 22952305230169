<template>
  <div class="d-flex flex-column">
    <div class="paragraph-heavy text-base-black px-16">
      {{ date }}
    </div>
    <molecule-modal v-if="isInProlongInterval">
      <template #activator>
        <atom-button navbar class="p-0 px-16 mt-4 paragraph-small text-decoration-underline">
          {{ t('extendStorageModalBtn') }}
        </atom-button>
      </template>

      <template #content>
        <modal-content :title="t('calendarModalTitle')" :illustration="IllustrationCalendar">
          <template #description>
            {{ t('calendarModalDesc') }}
          </template>
          <template #content>
            <atom-date-input
              name="calendar"
              type="date"
              class="calendar-input"
              v-bind="dateValues"
              @input="handleInput($event)"
            />
          </template>
        </modal-content>
      </template>

      <template #footer="{ close }">
        <div
          class="d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-end gap-16"
        >
          <atom-button
            outline-grey
            large
            class="paragraph-heavy px-24 justify-content-center"
            @click="close()"
          >
            {{ t('backBtn') }}
          </atom-button>
          <atom-button
            large
            :disabled="submitting"
            class="extend-storage-btn paragraph-heavy px-48 justify-content-center"
            @click="submitProlong(close)"
          >
            {{ t('extendStorageBtn') }}
          </atom-button>
        </div>
      </template>
    </molecule-modal>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import IllustrationCalendar from '~assets/illustrations/illustration-calendar.svg'
import { DateTypes } from '~common/enums/time.ts'
import {
  getDateDDMMYYYY,
  getNextDayDateString,
  isDateInRange,
  isInPathTodayOrFuture,
  routerPushSsr,
} from '~common/helpers'
import { encryptObject } from '~common/helpers/cryptoUtills.ts'
import type { ProlongTokenData, StoredUntilProps } from '~common/types'

const AtomButton = defineAsyncComponent(() => import('~components/atoms/AtomButton.vue'))
const AtomDateInput = defineAsyncComponent(() => import('~components/atoms/AtomDateInput.vue'))
const MoleculeModal = defineAsyncComponent(() => import('~components/molecules/MoleculeModal.vue'))
const ModalContent = defineAsyncComponent(() => import('~components/shared/ModalContent.vue'))

const { t } = useI18n()
const localePath = useLocalePath()

const props = defineProps<StoredUntilProps>()

const extendNewDate = ref(getNextDayDateString(1, props.storedUntil))
const date = computed(() => {
  return getDateDDMMYYYY(props.storedUntil)
})
const submitting = ref(false)

const handleInput = (value?: string | null) => {
  if (typeof value !== 'string') {
    return
  }
  extendNewDate.value = value
}

const submitProlong = async (close: () => void) => {
  if (submitting.value) {
    return
  }

  submitting.value = true

  const jsonData: ProlongTokenData = {
    prolongDate: extendNewDate.value,
    oldDate: props.storedUntil,
    actionDate: new Date().toString(),
  }

  const eToken = await encryptObject(jsonData)

  const url = localePath({
    name: 'id',
    params: { id: `Z${props.barcode}` },
    query: { e: eToken },
  })
  routerPushSsr(url)

  submitting.value = false
  close()
}

const dateValues = computed(() => {
  const min = getNextDayDateString(1, props.storedUntil)
  const minStoredBeforeToday = isInPathTodayOrFuture(min)

  const value = getNextDayDateString(0, extendNewDate.value)
  const valueDateBeforeToday = isInPathTodayOrFuture(value)

  return {
    min:
      minStoredBeforeToday === DateTypes.FUTURE
        ? min
        : getNextDayDateString(0, new Date().toString()),
    max: getNextDayDateString(-1, props.canProlong || ''),
    value:
      valueDateBeforeToday === DateTypes.FUTURE
        ? value
        : getNextDayDateString(0, new Date().toString()),
  }
})

const isInProlongInterval = computed(() => {
  if (!props.canProlong) {
    return
  }

  const isPast = isInPathTodayOrFuture(props.storedUntil)
  if (isPast === DateTypes.PATH) {
    return
  }

  return isDateInRange(
    props.storedUntil,
    props.canProlong,
    getNextDayDateString(1, props.storedUntil),
  )
})
</script>

<style lang="scss" scoped>
.extend-storage-btn {
  width: 100%;
}

@media (min-width: $media-md) {
  .calendar-input {
    width: 50%;
  }

  .extend-storage-btn {
    width: auto;
  }
}
</style>
