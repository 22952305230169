import { GetPacketByIdResponseDataItemPacketStatusId, PacketInfoItemType } from '~common/enums'
import { getFormattedShipmentNumber } from '~common/helpers/shipmentNumber.ts'
import type { GetPacketByIdResponseDataItem, LeftSidePacketInfoItemProps } from '~common/types'
import StoredUntil from '~components/pages/id/left-side/StoredUntil.vue'
import Cod from '~components/shared/Cod.vue'

const getInfoItems = (
  data: GetPacketByIdResponseDataItem,
  t: (key: string) => string,
): LeftSidePacketInfoItemProps[] => {
  const {
    cod,
    showCodPaid,
    currency,
    storedUntil,
    packetStatusId,
    barcode,
    branchAddress,
    canProlong,
    courierId,
  } = data

  // COD
  let codItem: LeftSidePacketInfoItemProps = {
    id: PacketInfoItemType.CAN_PAY_COD,
    label: t('cod'),
  }
  if (!parseInt(cod)) {
    codItem = { ...codItem, text: t('withoutCod') }
  } else {
    codItem = {
      ...codItem,
      component: Cod,
      componentProps: { showCodPaid, cod, currency, packetStatusId, barcode },
    }
  }
  // PROLONG
  let prolongItem: LeftSidePacketInfoItemProps | undefined = {
    id: PacketInfoItemType.CAN_ADDRESS_PROLONG,
    label: t('canAddressProlong'),
  }
  if (
    storedUntil &&
    packetStatusId === GetPacketByIdResponseDataItemPacketStatusId.READY_FOR_PICKUP
  ) {
    prolongItem = {
      ...prolongItem,
      component: StoredUntil,
      componentProps: { storedUntil, branchAddress, canProlong, barcode },
    }
  } else {
    prolongItem = undefined
  }
  // BRANCHADDRESS
  let branchAddressItem: LeftSidePacketInfoItemProps | undefined
  if (data.branchAddress) {
    branchAddressItem = {
      id: PacketInfoItemType.BRANCH_ADDRESS,
      label: t('branchAddress'),
      text: data.branchAddress,
      link: courierId === '1' ? undefined : data.branchUrl,
      tooltipText: t(`${PacketInfoItemType.BRANCH_ADDRESS}Tooltip`),
    }
  }

  return [
    {
      id: PacketInfoItemType.BARCODE,
      label: t(PacketInfoItemType.BARCODE),
      text: getFormattedShipmentNumber(data.barcode),
      copy: true,
      tooltipText: t(`${PacketInfoItemType.BARCODE}Tooltip`),
      tooltipTextFallback: t(`${PacketInfoItemType.BARCODE}TooltipFallback`),
    },
    {
      id: PacketInfoItemType.SENDER,
      label: t(PacketInfoItemType.SENDER),
      text: data.sender,
      link: data.senderUrl,
      tooltipText: t(`${PacketInfoItemType.SENDER}Tooltip`),
    },
    prolongItem,
    codItem,
    branchAddressItem,
  ].filter((item) => !!item) as LeftSidePacketInfoItemProps[]
}

export { getInfoItems }
