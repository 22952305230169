enum PacketInfoItemType {
  BARCODE = 'barcode',
  SENDER = 'sender',
  CAN_ADDRESS_REDIRECT = 'canAddressRedirect',
  CAN_ADDRESS_PROLONG = 'canAddressProlong',
  CAN_PAY_COD = 'cod',
  BRANCH_ADDRESS = 'branchAddress',
  ORDER_NUMBER = 'orderNumber',
  BRANCH_ADDRESS_FULL = 'branchAddressFull',
  OPENING_HOURS = 'openingHours',
  CARD_PAYMENT = 'cardPayment',
}

export { PacketInfoItemType }
