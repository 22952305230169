<template>
  <div class="d-flex flex-column px-24 px-sm-48 px-lg-0">
    <component :is="illustration" v-if="illustration && showIllustration" />
    <div v-if="lottie" class="lottie-wrapper mb-16">
      <atom-lottie :name="lottie" loop autoplay />
    </div>
    <div
      class="text-base-black mb-8"
      :class="{
        'heading-h5-heavy': viewport.isGreaterOrEquals('md'),
        'heading-h6-heavy': viewport.isLessThan('md'),
      }"
    >
      {{ title }}
    </div>
    <div
      v-if="slots.description"
      class="text-base-grey mb-32"
      :class="{
        'paragraph-large': viewport.isGreaterOrEquals('md'),
        paragraph: viewport.isLessThan('md'),
      }"
    >
      <slot name="description" />
    </div>
    <slot name="content" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { ModalContentProps } from '~common/types'
import AtomLottie from '~components/atoms/AtomLottie.vue'

const viewport = useViewport()
const slots = useSlots()

const props = defineProps<ModalContentProps>()

const showIllustration = computed(() => {
  if (!props.withoutMobileIllustration) {
    return true
  }

  return viewport.isGreaterOrEquals('md')
})
</script>

<style lang="scss" scoped>
.lottie-wrapper {
  width: 160px;
}
</style>
