import { DateTypes } from '~common/enums/time.ts'
import type { GetPacketByIdResponseDataItemTrackingDetails } from '~common/types'

const sortItemsByTime = (
  items: GetPacketByIdResponseDataItemTrackingDetails[],
): GetPacketByIdResponseDataItemTrackingDetails[] => {
  return items.sort((a, b) => {
    // Convert the time strings to Date objects and compare them
    const dateA = new Date(a.time).getTime()
    const dateB = new Date(b.time).getTime()

    // Order from newest to oldest (descending order)
    return dateB - dateA
  })
}

const getDateDDMMYYYY = (date: string) => {
  const d = new Date(date)
  const day = d.getDate()
  const month = d.getMonth()
  const year = d.getFullYear()

  return `${day}. ${month + 1}. ${year}`
}

const getTimeHHMM = (date: string) => {
  const d = new Date(date)
  const hours = d.getHours()
  const minutes = d.getMinutes()

  return `${hours}:${minutes > 9 ? minutes : `0${minutes}`}`
}

const getNextDayDateString = (plusDays = 1, date?: string) => {
  const today = date ? new Date(date) : new Date()
  const nextDay = new Date(today)
  nextDay.setDate(today.getDate() + plusDays)

  const day = String(nextDay.getDate()).padStart(2, '0')
  const month = String(nextDay.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const year = nextDay.getFullYear()

  return `${year}-${month}-${day}`
}

const isDateInRange = (from: string, to: string, checkDate: string): boolean => {
  // Parse the dates from string format
  const fromDate = new Date(from)
  const toDate = new Date(to)
  const check = new Date(checkDate)

  // Ensure the time portion is ignored for accurate comparison
  fromDate.setHours(0, 0, 0, 0)
  toDate.setHours(0, 0, 0, 0)
  check.setHours(0, 0, 0, 0)

  // Check if checkDate is between from and to, excluding the 'to' date itself
  return check > fromDate && check < toDate
}

const isInPathTodayOrFuture = (dateString: string) => {
  // Create a Date object from the input string
  const parsedDate = new Date(dateString)

  // Check if the Date object is valid
  if (isNaN(parsedDate.getTime())) {
    return null
  }

  parsedDate.setHours(0, 0, 0, 0)

  // Get today's date at midnight
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  // Compare the parsed date with today's date
  if (parsedDate < today) {
    return DateTypes.PATH
  } else if (parsedDate.getTime() === today.getTime()) {
    return DateTypes.TODAY
  } else {
    return DateTypes.FUTURE
  }
}

export {
  sortItemsByTime,
  getDateDDMMYYYY,
  getTimeHHMM,
  getNextDayDateString,
  isDateInRange,
  isInPathTodayOrFuture,
}
