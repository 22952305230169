<template>
  <id-page v-if="data" :data="data" />
  <div v-if="error" class="loading d-flex justify-content-center">
    <atom-load-spinner outer class="mt-120" />
  </div>
</template>

<script setup lang="ts">
import { useAsyncData, useRoute, useRouter } from 'nuxt/app'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { type Locales, LogTrackErrorEventType, LogTrackInfoEventType } from '~common/enums'
import { Status } from '~common/enums'
import { errorTrackEvent, infoTrackEvent, localeHreflangLinks } from '~common/helpers'
import type { GetPacketByIdResponseData } from '~common/types'
import AtomLoadSpinner from '~components/atoms/AtomLoadSpinner.vue'
import IdPage from '~components/pages/id/index.vue'
import { useGlobalStore } from '~stores/global.store.ts'

const route = useRoute()
const router = useRouter()
const id = route.params.id
const extendToken = route.query.e
const { t, locale, availableLocales } = useI18n()
const localePath = useLocalePath()
const globalStore = useGlobalStore()
const config = useRuntimeConfig()

const idWithoutLeadingZ = computed(() => {
  return `${id}`.replaceAll(/\s/g, '').toLowerCase().replaceAll('z', '')
})

const { data, error } = await useAsyncData<GetPacketByIdResponseData>('getPacket', () => {
  return $fetch(`/api/getPacketById/${idWithoutLeadingZ.value}/${locale.value}`, {
    method: 'POST',
    body: {
      pToken: extendToken,
    },
  })
})

if (error.value) {
  globalStore.setMoleculeAlertBadge({
    status: Status.ERROR,
    title: t(`badgeShipmentError.${error.value.statusCode}.title`),
    text: t(`badgeShipmentError.${error.value.statusCode}.text`),
    visible: true,
  })
  errorTrackEvent(LogTrackErrorEventType.ID_PAGE_FETCH_DETAIL, {
    id,
    eQuery: extendToken,
    error: error.value,
  })
}

if (data.value && data.value.prolonged && typeof data.value.prolonged === 'boolean') {
  const badgeData = {
    status: data.value.prolonged ? Status.SUCCESS : Status.ERROR,
    title: t(`badgeProlong${data.value.prolonged ? '' : 'Error'}.title`),
    text: t(`badgeProlong${data.value.prolonged ? '' : 'Error'}.text`),
    visible: true,
  }
  if (!data.value.prolonged) {
    errorTrackEvent(LogTrackErrorEventType.ID_PAGE_PROLONG_DETAIL, { id, eQuery: extendToken })
  }
  globalStore.setMoleculeAlertBadge(badgeData)
}

onMounted(() => {
  if (error.value) {
    router.push(localePath('index'))
  } else {
    infoTrackEvent(LogTrackInfoEventType.ID_PAGE_SHOW, { id })
  }
})

useHead({
  link: [
    ...localeHreflangLinks(config.public.webUrl, availableLocales as Locales[], route, localePath),
  ],
})
</script>

<style lang="scss" scoped>
@media (min-width: $media-lg) {
  .loading {
    min-height: calc(100dvh - 71px);
  }
}
</style>
